<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName == "trainer-orientation-assessment-update"
                ? "Edit Record"
                : "Add Record"
            }}
          </h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedProgram"
                        v-model="selectedProgram"
                        label="name"
                        :options="programs"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedBatch"
                        v-model="selectedBatch"
                        label="batch_no"
                        :options="batch"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedEmployee"
                        v-model="selectedEmployee"
                        label="employee_name"
                        :options="employeeLists"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedEmployee.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedEmployee.$errors,
                          value: 'Employee Name',
                        }"
                      ></v-errors>
                    </div>
                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee ID
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="orientation.employee_id"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="date" class="col-md-3 form-label">
                      Joined Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="orientation.join_date"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row g-3 mt-3">
                <div class="col-sm-4">
                  <h4 class="mb-3">Criteria</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Scores:*</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Remark:</h4>
                </div>
              </div>

              <div
                class="row g-3"
                v-for="(criteria, index) in criterias"
                :key="index"
              >
                <div class="col-sm-4">
                  <p class="mb-3 pt-2 pl-2">{{ criteria.name }}</p>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2">
                    <v-select
                      class="w-100 selectScore"
                      v-model="criteria.selectedScore"
                      :options="scoreOptions(criteria)"
                      :clearable="false"
                      @option:selected="updateTotalScore"
                    ></v-select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2">
                    <input
                      type="text"
                      class="form-control mt-2"
                      placeholder="Remark"
                      aria-label="Remark"
                      v-model="criteria.remark"
                    />
                  </div>
                </div>
              </div>
              <div class="row g-3">
                <div class="col-sm-4">Total Score</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2 scoreBox"
                    v-model="totalScore"
                    disabled
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link
                      :to="{ name: 'trainer-orientation-assessment' }"
                    >
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName ===
                        'trainer-orientation-assessment-update'
                          ? updateTrainerOrientationAssessment()
                          : createTrainerOrientationAssessment()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  mixins: [validationErrors],
  data() {
    return {
      programs: [],
      batch: [],
      employeeLists: [],
      criterias: [],
      totalScore: 0,
      orientation: {
        type: "Orientation",
        program_id: "",
        program_name: "",
        program_short_name: "",
        program_batch_id: "",
        program_batch_no: "",
        join_date: "",
        available: "",
      },
      selectedProgram: null,
      selectedBatch: null,
      selectedEmployee: null,
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      selectedEmployee: { required },
      orientation: {
        program_id: { required },
        program_name: { required },
        program_short_name: { required },
        program_batch_id: { required },
        program_batch_no: { required },
        join_date: { required },
        available: { required },
      },
    };
  },
  methods: {
    async getAllCriterias(program_id) {
        await axios.get(`${this.baseUrl}admin/v1/trainer-assessment-getCriteria/${program_id}/Orientation/null`)
                .then((response) => {
                    response.data.data.forEach((criteria) => {
                        this.criterias.push({
                            name: criteria.name,
                            criteria_id: criteria.id,
                            selectedScore: criteria.lowest_score,
                            highest_score: criteria.highest_score,
                            lowest_score: criteria.lowest_score,
                            remark: null
                        });
                        this.totalScore += criteria.lowest_score;
                    });
                })
    },
    getSelectedProgram() {
      if (this.selectedProgram) {
        this.selectedBatch = null;
        this.criterias = [];
        this.totalScore = 0;
        this.clearData();
        this.orientation.program_id = this.selectedProgram.id;
        this.orientation.program_name = this.selectedProgram.name;
        this.orientation.program_short_name = this.selectedProgram.short_name;
        this.getAllCriterias(this.orientation.program_id);
        this.getBatchByProgram(this.orientation.program_id);
      }
    },
    getSelectedBatch() {
      if (this.selectedBatch) {
        this.clearData();
        this.orientation.program_batch_id = this.selectedBatch.id;
        this.orientation.program_batch_no = this.selectedBatch.batch_no;
        this.orientation.available = this.selectedBatch.available;
        this.getEmployeeByBatch(this.orientation.program_batch_id);
      }
    },
    getSelectedEmployee() {
      if (this.selectedEmployee) {
        this.orientation.employee_id =
          this.selectedEmployee.employee_id;
        this.orientation.employee_name =
          this.selectedEmployee.employee_name;
        this.orientation.join_date = this.selectedBatch.join_date;       
      }
    },
    clearData() {
      this.selectedEmployee = null;
      this.orientation.employee_id = "";
      this.orientation.employee_name = "";
      this.orientation.join_date = "";
    },

     updateTotalScore() {
      let totalScore = 0;
      for (let i = 0; i < this.criterias.length; i++) {
        totalScore += this.criterias[i].selectedScore;
      }
      this.totalScore = totalScore;
    },

    scoreOptions(criteria) {
      const options = [];
      for (let i = criteria.lowest_score; i <= criteria.highest_score; i++) {
        options.push(i);
      }
      return options;
    },

    async getBatchByProgram(program_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrlHRIS}api/program-batches/list/?program_id=${program_id}`
        )
        .then((response) => {
          this.batch = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getEmployeeByBatch(batch_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrlHRIS}api/program-batch/employees/list/?batch_id=${batch_id}`
        )
        .then((response) => {
          this.employeeLists = response.data.data[0].employee_ids.map(
            (item) => {
              return {
                employee_name: item.name,
                employee_id: item.employee_ID,
              };
            }
          );

          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Employee!");
        });
    },
    async getPrograms() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
 
    async createTrainerOrientationAssessment() {
      const isFormCorrect = await this.v$.$validate();
      if(!isFormCorrect) return;
      this.isLoading = true;
      
        let criteria = this.criterias.map((criteria) =>{
        return { criteria_id: criteria.criteria_id, selectedScore: criteria.selectedScore, message: criteria.remark };
        });

        let toSendData = this.orientation;
        toSendData.assessor_employee_id = this.$store.state.auth.user.employee_id;
        toSendData.assessor_employee_name = this.$store.state.auth.user.name;
        toSendData.total_score = this.totalScore;
        toSendData.criteria = criteria;
        toSendData.level = null;

      await axios
          .post(`${this.baseUrl}admin/v1/trainer-assessment`, toSendData ,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
          )
          .then(() => {
            this.toast.success('Successfully Orientation Assessment Created.');
            this.$router.push({ name: 'trainer-orientation-assessment'});
            this.isLoading = false;
          })
          .catch((error) => {
            this.error = error.response;
            this.toast.error("Something went wrong.");
            this.isLoading = false;
          })
    },
    async getOrientationAssessmentById() {
      this.loading = true;
      await axios 
          .get(`${this.baseUrl}admin/v1/trainer-assessment/${this.$route.params.id}`)
          .then((response) => {
            const data = response.data.data;
            this.selectedProgram = data.trainer_assessment.program_name;
            this.selectedBatch = data.trainer_assessment.program_batch_no;
            this.selectedEmployee = data.employee_name;
            this.orientation = data.trainer_assessment;
            this.orientation.employee_id = data.employee_id;
            this.criterias = data.criteria;
            this.totalScore = data.total_score;
            this.loading = false;
          })
          .catch((error) => {
            this.error = error.response;
            this.toast.error("Something went wrong.");
            this.loading = false;
          })
    },
    async updateTrainerOrientationAssessment() {
      const isFormCorrect = await this.v$.$validate();
      if(!isFormCorrect) return;
      
      this.isLoading = true;

       let criteria = this.criterias.map((criteria) =>{
        return { criteria_id: criteria.criteria_id, selectedScore: criteria.selectedScore, message: criteria.remark };
        });
        let toSendData = this.orientation;
        toSendData.total_score = this.totalScore;
        toSendData.criteria = criteria;
        toSendData.level = null;

      await axios
          .put(`${this.baseUrl}admin/v1/trainer-assessment/${this.$route.params.id}`, toSendData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
          )
          .then(() => {
            this.toast.success('Successfully Orientation Assessment Updated.');
            this.$router.push({ name: 'trainer-orientation-assessment'});
            this.isLoading = false;
          })
          .catch((error) => {
            this.error = error.response;
            this.toast.error("Something went wrong.");
            this.isLoading = false;
          })

    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },

  created() {
    this.getPrograms();
    if(this.currentRouteName == "trainer-orientation-assessment-update") {
      this.getOrientationAssessmentById();
    }
  },

};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
.selectScore {
  padding: 0.45rem 0.9rem;
}
.scoreBox {
  width: 262px;
  margin-left: 12px;
}
</style>
